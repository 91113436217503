/**
 * Layout priorities.
 * @enum {String}
 */
var Priority = {
    REQUIRED: 1000,
    DEFAULTHIGH: 750,
    DEFAULTLOW: 250,
    //FITTINGSIZELEVEL: 50,
};
export default Priority;
